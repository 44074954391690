import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layouts"
import SEO from "../components/seo"

import JournalGrid from "../components/new-ui/journal-grid"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: calc(${(props) => props.theme.navbar.height} + 2rem) 20px;

  .page-title {
    text-align: left;
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 400;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: calc(${(props) => props.theme.navbar.height} + 2rem) 0;

    .page-title {
      text-align: center;
      font-size: 46px;
      margin-bottom: 112px;
    }
  }
`

const AllJournals = ({ data }) => {
  return (
    <Layout almostWhite>
      <SEO title="Projects" canonicalUrl="/projects" breadcrumbsList={
        [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://teklafabrics.com"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Projects",
            "item": "https://teklafabrics.com/projects"
          }
        ]
      } />
      <Container>
        <h1 className="page-title">Projects</h1>
        <JournalGrid data={data} locale={"en-US"} />
      </Container>
    </Layout>
  )
}

export default AllJournals

export const query = graphql`
  {
    posts: allContentfulBlogPost(
      filter: {
        contentful_id: { ne: "agABfUOdyx9ZfH1Pz4Ays" }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          author
          date
          description
          slug
          createdAt
          isPortrait
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
